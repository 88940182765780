<template>
  <div class="service_board">
    <div class="j_datagrid__header type01">
      <div class="header__title">
        <h5>
          {{ $route.name }}
          <!-- <small>Progress of the timeline schedule.</small> -->
        </h5>
      </div>

      <div class="header__right">
        <v-flex>
          <button class="jcon_add" @click="onAdd"></button>
          <!-- <div class="j_search type01">
            <input v-model="search" type="search" name="q" placeholder="Filter and search" />
            <button type="submit">
              <v-icon>mdi-magnify</v-icon>
            </button>
          </div> -->
          <div class="view_type">
            <v-btn class="j_button type03" @click="setDisplayMode('list')">
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn>
            <v-btn class="j_button type03" @click="setDisplayMode('thumb-small')">
              <v-icon>mdi-view-comfy</v-icon>
            </v-btn>
            <v-btn class="j_button type03" @click="setDisplayMode('thumb')">
              <v-icon>mdi-view-grid</v-icon>
            </v-btn>
          </div>
        </v-flex>
      </div>
    </div>

    <div v-if="displayThumbSmall" class="thumb_list small">
      <div v-for="item in items" class="thumb_item" :key="item.idx">
        <div class="image_wrapper" @click="propStatus(item)">
          <img v-if="!!item.dataUrl" class="thumb" :src="item.dataUrl" width="170" />
          <div v-else class="no_image">
            <img src='../../assets/img/noimage.png' alt="no-image">
          </div>
        </div>
        <div class="content">
          <div class="name_arapper">
            <span class="cat_name">{{ getReportCatName(item.catcode, item.groupcode) }}</span>
            <span class="date">{{ item.cutoff }}</span>
          </div>
          <v-btn class="j_button type03" v-if="item.report" @click="onDownload(toJson(item.report))">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div v-else-if="displayThumb" class="thumb_list large">
      <div v-for="item in items" class="thumb_item" @click="propStatus(item)" :key="item.idx">
        <div class="image_wrapper">
          <img v-if="!!item.dataUrl" class="thumb" :src="item.dataUrl" />
          <div v-else class="no_image">
            <img src='../../assets/img/noimage.png' alt="no-image">
          </div>
        </div>
        <div class="content">
          <div class="title">{{ item.title }}</div>
          <div>
            <span class="writer">{{ item.userName }}</span>
            <span class="date">{{ item.cutoff }}</span>
          </div>
          <div v-html="item.content" class="comment"></div>
        </div>
      </div>
    </div>

    <j-data-grid
      v-else
      v-model="selected"
      class="chart"
      hide-actions
      :headers="headers"
      :items="items"
      :search="search"
    >
      <template v-slot:items="props">
        <tr :active="props.selected" @click="propStatus(props.item)">
          <td style="text-align: center">{{ props.item.no }}</td>
          <td>{{ getReportCatName(props.item.catcode, props.item.groupcode) }}</td>
          <td>{{ props.item.title }}</td>
          <td style="text-align: center">{{ props.item.cutoff }}</td>
          <td>{{ props.item.userName }}</td>
          <td style="text-align: center">{{ props.item.date }}</td>
          <td></td>
        </tr>
      </template>
    </j-data-grid>

    <slide-modal
      v-model="modalOpened"
      :cat-code="catcode"
      :key-value="itemKey"
      @saved="onSaved"
    >
    </slide-modal>
  </div>
</template>

<script>
import '@/assets/stylus/ui/component/_service.board.styl'

import { mapState, mapMutations, mapActions } from 'vuex'

import __C from '@/primitives/_constant_'
import __M from 'moment'
import Loading from '@/mixins/loading.mixin'
import FileServiceMixin from '@/mixins/file.service.mixin'
import StaticOptions from '@/mixins/staticOptions'
import { ApplicationService } from '@/services'

import SlideModal from './modal/ServiceBoard.modal'

export default {
  name: 'service-board',
  mixins: [
    FileServiceMixin,
    Loading,
    StaticOptions
  ],
  components: {
    SlideModal,
  },
  data: () => ({
    // Service Instances -------------
    appService: null,

    modalOpened: false,

    displayMode: 'thumb-small',
    catcode: '',
    itemKey: null,
    search: '',
    selected: [],

    headers: [
      { type: 'conf', text: 'No', align: 'center', value: 'no', sortable: false, width: 30, },
      { type: 'text', text: 'Category', align: 'left', value: 'groupcode', sortable: false, width: 120, },
      { type: 'text', text: 'Title', align: 'left', value: 'title', sortable: false, width: 350, },
      { type: 'text', text: 'Cutoff', align: 'center', value: 'cutoff', sortable: false, width: 80, },
      { type: 'text', text: 'Updated By', align: 'left', value: 'userName', sortable: false, width: 120, },
      { type: 'text', text: 'Updated Date', align: 'center', value: 'date', sortable: false, width: 80, },
      { type: 'text', text: '', sortable: false },
    ],
    items: [],
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APPLICATION, ['reportCategory']),

    displayList() { return this.displayMode == 'list' },
    displayThumbSmall() { return this.displayMode == 'thumb-small' },
    displayThumb() { return this.displayMode == 'thumb' },
  },
  created() {
    this.setChild(__C.STORE_NAMESPACE_KEY.BOARD_REPORT)
    this.appService = new ApplicationService()
  },
  mounted() {
    this.loading = false
    this.setCatCode()
    this.getReports()
  },
  watch: {
    modalOpened(val) {
      this.setModalOpened(val)
    },
    $route(to, from) {
      this.loading = false
      this.setCatCode()
      this.getReports()
    },
    reportCategory(val) {
      this.getReports()
    }
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setChild', 
      'setModalOpened'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.APPLICATION, [
      'getBoardReportValues', 
      'updateBoardReportValues'
    ]),

    onAdd() {
      this.itemKey = null
      this.modalOpened = true
    },
    onSaved() {
      this.getReports()
    },
    onDownload(report) {
      if(!this.downloadfile(report.idx, this.account.token)) return

      this.getBoardReportValues(this.catcode).then(values => {
        let recent__ = values.recent || []

        if(recent__.find(r => r.idx == report.idx)) return
        if(recent__.length == 10) recent__.shift()

        recent__.push({ idx: report.idx, name: report.name, date: __M().format('YYYY-MM-DD') })
        values.recent = recent__

        this.updateBoardReportValues({ 
          id: this.catcode,
          values: values
        })
      })
    },
    propStatus(item) {
      this.itemKey = item.idx
      this.modalOpened = true
    },

    getReports() {
      this.appService.getBoardReports(this.catcode, this.reportCategory).then(res => {
        this.items = res
      })
    },
    toJson(string) {
      if(!string) null
      return JSON.parse(string)
    },
    setCatCode() {
      if(this.$route.name == __C.BOARD.REPORT_CAT_NAME_WEEKLY) this.catcode = __C.BOARD.REPORT_CAT_CODE_WEEKLY
      else if(this.$route.name ==  __C.BOARD.REPORT_CAT_NAME_FABRICATOR) this.catcode = __C.BOARD.REPORT_CAT_CODE_FABRICATOR
      else if(this.$route.name ==  __C.BOARD.REPORT_CAT_NAME_JGS) this.catcode = __C.BOARD.REPORT_CAT_CODE_JGS
      else this.catcode = __C.BOARD.REPORT_CAT_CODE_SPECIAL

      this.getBoardReportValues(this.catcode).then(values => {
        this.displayMode = values.displayMode || 'thumb-small'
      })
    },
    setDisplayMode(mode) {
      this.displayMode = mode
      this.getBoardReportValues(this.catcode).then(values => {
        values.displayMode = mode

        this.updateBoardReportValues({ 
          id: this.catcode,
          values: values
        })
      })
    },
  }
}
</script>

<style>

</style>